import { classFormat } from "@wisr/common";
import { Accordion, Card } from "@wisr/react-ui";
import React from "react";
import { PageWrapperComponent } from "../../layout/page-wrapper/page-wrapper-layout.component";
import { useQueryString } from "../../shared/gatsby.utils";
import { GatsbyPageProps } from "../../types/gatsby";
import { CREDIT_FAQ } from "./faq.constants";
import pageStyle from "./faq.scss";

export const UnderstandYourScoresComponent: React.FC<GatsbyPageProps> = ({
  location,
}) => {
  const { q } = useQueryString(location);
  const questions = [
    "What is a credit score?",
    "Why do I have multiple scores?",
    "What is a 'good' credit score?",
    "Why are my scores so different?",
    "Why are one or more of my scores missing?",
    "Why has my score changed?",
    "Why did I get this score?",
    "Help, my score is wrong!",
    "What can I do with my credit score?",
    "Will checking my score hurt my credit score?",
    "What is Comprehensive Credit Reporting?",
  ];

  const SCROLL_DELAY = 500;
  const scrollRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    setTimeout(() => {
      if (scrollRef.current && q) {
        scrollRef.current.scrollIntoView({ behavior: "smooth" });
      }
    }, SCROLL_DELAY);
  }, [q]);

  return (
    <PageWrapperComponent size="half">
      <style jsx>{pageStyle}</style>
      <div className="prefix scores-container">
        <Card>
          <h1>Frequently asked questions</h1>
          {questions.map((question) => (
            <div
              key={classFormat(question)}
              ref={classFormat(question) === q ? scrollRef : null}
            >
              <Accordion
                title={question}
                startOpen={classFormat(question) === q}
                key={question}
              >
                {CREDIT_FAQ[question]}
              </Accordion>
            </div>
          ))}
        </Card>
      </div>
      <div className="suffix">
        <Card>
          <h3>Need to know more?</h3>
          <p>
            Got questions? Need answers? Get in touch with us at{" "}
            <a href="mailto:contact@wisr.com.au">contact@wisr.com.au</a>
          </p>
        </Card>
      </div>
    </PageWrapperComponent>
  );
};
