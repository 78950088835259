import React from "react";
import { BUREAU_TYPE } from "../../shared/analytics/analytics.consts";
import {
  BRAND_WEBSITE,
  EQUIFAX_CORRECTIONS,
  EXPERIAN_CORRECTIONS,
} from "../../shared/url.consts";
import { sendClickTrackingEvent } from "./faq-credit.utils";

type faqType = {
  [key: string]: React.ReactElement;
};

export const CREDIT_FAQ: faqType = {
  "What is a credit score?": (
    <>
      <p>
        A credit score is a number that financial institutions use to judge how
        likely you are to pay back any money they give you. The higher the
        score, the more stable and trustworthy they view you to be. The lower
        the score, erm well… you may have to give mum and dad a call. Just
        kidding!
      </p>
      <p>
        Your credit score is not the only factor lenders look at, but it
        typically plays a role.
      </p>
    </>
  ),
  "Why do I have multiple scores?": (
    <>
      <p>
        Credit scores are generated by credit bureaus. Basically, they collect
        all sorts of financial data to see whether you pay your bills on time,
        what your borrowing history looks like and more. The aim is to paint a
        picture of your creditworthiness.
      </p>
      <p>
        In Australia, there are three major ones – Equifax, Experian and Illion.
        When assessing loan applications, lenders can request a credit report
        from one or more of these bureaus.
      </p>
      <p>
        So, not to freak you out, but that means you actually have three credit
        scores in Australia.
      </p>
    </>
  ),
  "What is a 'good' credit score?": (
    <>
      <p>
        The bands used by the credit bureaus are a pretty good place to start
        when assessing how good your credit score is. That&apos;s because they
        take into account credit scores across the total credit active
        population.
      </p>
      <p>
        Credit scores are used and viewed by different lenders in different
        ways, however an easy way to look at it is – the higher the score the
        better.
      </p>
      <p>
        Check out our{" "}
        <a
          /* eslint-disable react/jsx-no-target-blank*/
          href={`${BRAND_WEBSITE}/smart-guides/credit-scores-101-guide`}
          target="_blank"
          rel="noopener nofollow"
        >
          Credit Scores 101 Smart Guide
        </a>{" "}
        to find out more and see a score comparison chart.
      </p>
    </>
  ),
  "Why are one or more of my scores missing?": (
    <>
      <p>
        Um, are you Jason Bourne – because you&apos;re totally off the grid?
      </p>
      <p>
        Nah, we&apos;re just kidding. Before you freak out, double check that
        all your details are correct:
      </p>
      <ul>
        <li>Date of birth</li>
        <li>Current address</li>
        <li>Identity document information</li>
      </ul>
      <p>
        You can also try a different identity document from our list of Drivers
        Licence, Passport or Medicare card.
      </p>
      <p>
        If you tried the above and are having no luck, you can contact{" "}
        <a
          href={EXPERIAN_CORRECTIONS}
          target="_blank"
          rel="noopener nofollow"
          onClick={() => {
            sendClickTrackingEvent(BUREAU_TYPE.EXPERIAN);
          }}
        >
          Experian
        </a>{" "}
        and{" "}
        <a
          href={EQUIFAX_CORRECTIONS}
          target="_blank"
          rel="noopener nofollow"
          onClick={() => {
            sendClickTrackingEvent(BUREAU_TYPE.EQUIFAX);
          }}
        >
          Equifax
        </a>{" "}
        credit bureaus directly to find out more about your scores.
      </p>
      <p>
        Your score is based on your existing credit history. This includes any
        accounts you&apos;ve opened or enquiries you&apos;ve made. If
        you&apos;ve never applied for or had a loan, credit card, or another
        line of credit (such as an electricity account or a phone plan), you may
        not have a credit report.
      </p>
      <p>
        You can contact{" "}
        <a
          href={EXPERIAN_CORRECTIONS}
          target="_blank"
          rel="noopener nofollow"
          onClick={() => {
            sendClickTrackingEvent(BUREAU_TYPE.EXPERIAN);
          }}
        >
          Experian
        </a>{" "}
        and{" "}
        <a
          href={EQUIFAX_CORRECTIONS}
          target="_blank"
          rel="noopener nofollow"
          onClick={() => {
            sendClickTrackingEvent(BUREAU_TYPE.EQUIFAX);
          }}
        >
          Equifax
        </a>{" "}
        credit bureaus directly to find out more about your scores.
      </p>
    </>
  ),
  "Why did I get this score?": (
    <>
      <p>
        Going through your credit file is a great way to take control of your
        financial standing, but it&apos;s not the easiest thing in the world to
        decipher. Read our article,{" "}
        <a
          /* eslint-disable react/jsx-no-target-blank*/
          href={`${BRAND_WEBSITE}/blog/how-to-read-and-understand-your-credit-file`}
          rel="noopener nofollow"
          target="_blank"
        >
          How to Read and Understand Your Credit File
        </a>
        , to find out what might be affecting your score.
      </p>
      <p>
        Alternatively if you believe there may be an error with your credit
        score, take a look at the{" "}
        <a
          /* eslint-disable react/jsx-no-target-blank*/
          href={`${BRAND_WEBSITE}/blog/credit-report-errors-to-look-out-for`}
          rel="nofollow noopener"
          target="_blank"
        >
          Credit Report Errors to Look Out For
        </a>
        .
      </p>
    </>
  ),
  "How do I check my credit scores?": (
    <>
      <p>Getting your hands on a free credit report is pretty simple.</p>
      <p>
        Each credit bureau is legally required to give you a free credit score
        check once every 12 months. You can also get a free credit report if a
        credit application has been rejected within the last 90 days.
      </p>
      <p>
        Ready for us to blow your mind? You can see your credit scores –
        that&apos;s right, scores with an s – with our{" "}
        <a
          /* eslint-disable react/jsx-no-target-blank*/
          href={`${BRAND_WEBSITE}/credit-scores`}
          rel="noopener nofollow"
          target="_blank"
        >
          free credit check
        </a>
        . Wisr is the only site in Australia that lets you check multiple credit
        scores in one go in just a couple minutes.
      </p>
      <p>
        If you have too much free time on your hands, or if you just like doing
        unnecessary work, you can always contact each of the credit bureaus
        individually. But ain&apos;t nobody got time for that.
      </p>
    </>
  ),
  "Why are my scores so different?": (
    <>
      <p>
        Even though each credit bureau collects roughly the same type of
        information – previous applications for finance, defaults and
        bankruptcies – the way they interpret this data varies. This is why your
        scores might differ from bureau to bureau.
      </p>
      <p>
        Check out this article for more information about the{" "}
        <a
          /* eslint-disable react/jsx-no-target-blank*/
          href={`${BRAND_WEBSITE}/blog/anatomy-of-your-credit-score`}
          rel="noopener"
          target="_blank"
        >
          Anatomy of Your Credit Score
        </a>
        .
      </p>
    </>
  ),
  "Why has my score changed?": (
    <>
      <p>
        Your credit score is always changing based on your financial behaviour.
      </p>
      <p>
        Let&apos;s say you changed your mobile provider for the third time this
        year to save some cash. Before you pat yourself on the back for scoring
        an extra 2GB of data, you might like to know that it could be impacting
        your credit scores. Changing mobile providers is just one of the
        little-known factors that can hit your credit scores.
      </p>
      <p>
        Check out this article about{" "}
        <a
          /* eslint-disable react/jsx-no-target-blank*/
          href={`${BRAND_WEBSITE}/blog/five-surprising-things-that-can-affect-your-credit-score`}
          rel="noopener"
          target="_blank"
        >
          Five Surprising Things That Can Affect Your Credit Score
        </a>{" "}
        to find out more.
      </p>
    </>
  ),
  "Help, my score is wrong!": (
    <>
      <p>
        There are a few reasons why your score might not look right. It could be
        because of a reporting error made by your credit provider, a delay in
        syncing your information, or worst-case scenario: fraud.
      </p>
      <p>
        Errors can be changed. The information that credit bureaus collect comes
        from credit providers ie. banks, phone companies and utility providers.
        The quickest way to fix an error is to speak directly with the credit
        provider that provided the information. They&apos;re obligated to sort
        out any mistakes in their reporting – so make sure to hassle them.
      </p>
      <p>
        We do our best to make sure that your credit report is up to date on our
        end. When your credit report changes, it can sometimes take a few days
        for this to be reflected on your Wisr credit check. But don&apos;t
        stress, this doesn&apos;t affect your borrowing power and will usually
        be updated in no time.
      </p>
      <p>
        If your sensitive information makes its way into the wrong hands, you
        might find yourself becoming the victim of credit fraud. Checking your
        scores regularly will help you pick up any activity you don&apos;t
        recognise. As soon as you see something that doesn&apos;t look quite
        right, you can report it to your bank. Read our article on{" "}
        <a
          /* eslint-disable react/jsx-no-target-blank*/
          href={`${BRAND_WEBSITE}/blog/combating-fraud-why-you-should-check-your-credit-history`}
          rel="noopener"
          target="_blank"
        >
          combating credit fraud
        </a>{" "}
        to find out more.
      </p>
    </>
  ),
  "What can I do with my credit score?": (
    <>
      <p>
        Your credit score is important because many big life purchases (for
        example a car or home) usually requires a loan. The better your credit
        score, the better your likelihood of getting approved, and snagging a
        lower interest rate.
      </p>
      <p>
        A good credit score over a lifetime can not only save you tens or even
        hundreds of thousands of dollars, but it can improve your overall
        quality of life.
      </p>
      <p>
        Protect your credit rating because it is literally money in your pocket.
      </p>
    </>
  ),
  "Will checking my score hurt my credit score?": (
    <>
      <p>
        This is the biggest credit myth out there! We make a &lsquo;soft
        enquiry&rsquo; with participating credit reporting bureaus. That means
        it is technically recorded, but it does not impact your credit score in
        the slightest.
      </p>
      <p>
        Credit providers are also unable to see soft enquiries, so no dramas.
      </p>
    </>
  ),
  "What is Comprehensive Credit Reporting?": (
    <>
      <p>
        Comprehensive Credit Reporting (CCR) - also known as Positive Credit
        Reporting - means that banks and lenders can now see more data in your
        credit report when deciding whether or not you&apos;re able to repay a
        loan or other lines of credit.
      </p>
      <p>
        Up until 2014, lenders had limited access to your credit history. They
        could only see whether you had any negative information (ie. credit
        enquiries, overdue debts, defaults, bankruptcy etc) on your credit
        report. This focus on negative actions made it harder for people with
        good credit to be seen as trustworthy borrowers since lenders only had
        access to limited information.
      </p>
      <p>
        Now, positive aspects of your credit report are being added—hence the
        name Positive Credit Reporting—to give banks and other lenders a bigger
        picture of your finances.
      </p>
      <p>
        We run through this here -{" "}
        <a
          /* eslint-disable react/jsx-no-target-blank*/
          href={`${BRAND_WEBSITE}/blog/what-is-comprehensive-credit-reporting`}
          rel="noopener"
          target="_blank"
        >
          What is Comprehensive Credit Reporting (CCR)?
        </a>
        .
      </p>
    </>
  ),
  "Why do I have a negative credit score?": (
    <>
      <p>
        Each credit bureau has its own scale for generating credit scores.
        Equifax, for example, uses negative score codes in special circumstances
        where a positive score cannot be produced. There are several reasons you
        might be seeing a negative score, including:
      </p>
      <ul>
        <li>You&apos;ve filed for bankruptcy in the last 5 years</li>
        <li>You have a serious default recorded on your credit file</li>
        <li>
          You or your company are in a state of insolvency i.e. unable to pay
          your debts
        </li>
        <li>
          The credit bureaus don&apos;t have enough information about you to
          provide a score
        </li>
        <li>The credit bureaus think you&apos;re dead</li>
      </ul>
    </>
  ),
  "How long until my scores improve?": (
    <>
      <p>
        Well, that largely depends on you, my friend. If you get on top of your
        debts, make your repayments on time and continually demonstrate good
        credit behaviour, your credit score will recover over time. Certain
        enquiries and infringements stay on your file for up to 5 years,
        including bankruptcy and serious defaults. If you&apos;re assumed dead,
        you might want to follow up with the credit bureaus ASAP.
      </p>
    </>
  ),
  "I dont think this is right. What should I do?": (
    <>
      <p>
        If you&apos;re not dead, not bankrupt and don&apos;t have any serious
        defaults on your file, it may simply mean you don&apos;t have a long
        enough credit history for the credit bureaus to generate a credit score.
        It&apos;s a good idea to get in touch with the bureaus directly to find
        out what might be happening. This is a great way to ensure there are no
        mistakes on your file, or worse, any suspicious activity going on. Visit
        the
        <a
          /* eslint-disable react/jsx-no-target-blank*/
          href={"https://www.equifax.com.au/contact"}
          rel="noopener"
          target="_blank"
        >
          {" "}
          Equifax{" "}
        </a>
        or
        <a
          /* eslint-disable react/jsx-no-target-blank*/
          href={"https://www.experian.com.au/contact-us"}
          rel="noopener"
          target="_blank"
        >
          {" "}
          Experian{" "}
        </a>
        website for more info.
      </p>
    </>
  ),
};
