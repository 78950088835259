import React from "react";
import { UnderstandYourScoresComponent } from "../../../credit/faq/faq-credit.component";
import { analyticsViewPageEvent } from "../../../shared/analytics/analytics.utils";
import { isBrowser } from "@wisr/common";
import { GatsbyPageProps } from "../../../types/gatsby";

const FaqPage: React.FC<GatsbyPageProps> = ({ location }) => {
  React.useEffect(() => {
    analyticsViewPageEvent("Understand your scores");
  }, []);

  if (!isBrowser()) {
    return null;
  }
  return <UnderstandYourScoresComponent location={location} />;
};

export default FaqPage;
